<template>
  <div id="user-list">
    <!-- app drawer -->
    <user-list-add-new
      v-model="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :edit-data="editUserData"
      @set-message="setMessage"
      @refetch-data="fetchUsers"
    ></user-list-add-new>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        用户列表
      </v-card-title>
      <v-row v-if="false" class="px-2 ma-0">
        <!-- role filter -->
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="roleFilter"
            placeholder="Select Role"
            :items="roleOptions"
            item-text="title"
            item-value="value"
            outlined
            clearable
            dense
            hide-details
          ></v-select>
        </v-col>

        <!-- plan filter -->
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="planFilter"
            placeholder="Select Plan"
            :items="planOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>

        <!-- status filter -->
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="statusFilter"
            placeholder="Select Status"
            :items="statusOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <!-- <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field> -->

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click.stop="isAddNewUserSidebarActive = !isAddNewUserSidebarActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>添加新用户</span>
          </v-btn>

          <!-- <v-btn
            color="secondary"
            outlined
            class="mb-4"
            @click="exportFunc"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>导出</span>
          </v-btn> -->
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="userListTable"
        filter-keys="name"
        :sort-by="sortBy"
        sort-desc
        :loading="loading"
        :items-per-page="10000"
        :hide-default-footer="true"
      >
        <!-- name -->
        <template #[`item.fullName`]="{item}">
          <div class="d-flex align-center">
            <!-- <v-avatar
              :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.avatar"
                :src="require(`@/assets/images/avatars/${item.avatar}`)"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.fullName) }}</span>
            </v-avatar> -->

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'apps-user-view', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.fullName }}
              </router-link>
              <small>@{{ item.username }}</small>
            </div>
          </div>
        </template>

        <!-- plan -->
        <template #[`item.created_at`]="{item}">
          <span class="text-capitalize font-weight-semibold text--primary">{{ item.created_at }}</span>
        </template>

        <!-- status -->
        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="resolveUserStatusVariant(item.status)"
            :class="`${resolveUserStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.status }}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                 @click="statusUser(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>{{item.status === "active" ? "停用" : "启用"}}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="editUser(item)">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiLockReset }}
                  </v-icon>
                  <span>修改密码</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteUser(item.id)">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>删除用户</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar :value="!!typeInfo.message" top right :timeout="3000" :color="typeInfo.type">
      <div class="d-flex" style="justify-content: space-between;align-items: center;">
        <div>
          <v-icon color="white">mdi-alert-circle</v-icon>
          <span class="text-white">{{typeInfo.message}}</span>
        </div>
        <div>
          <v-btn color="white" text @click="typeInfo.message = ''">关闭</v-btn>
        </div>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiLockReset,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import { onUnmounted, ref, watch } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import userStoreModule from '../userStoreModule'
import UserListAddNew from './UserListAddNew.vue'

import { closeUser, deleteUserApi, openUser } from '@/plugins/api/modules/user'
import { useRouter } from '@core/utils'
import useUsersList from './useUsersList'

export default {
  components: {
    UserListAddNew,
  },
  setup() {

    const { route } = useRouter()
    let sortBy = route.value.query?.sortBy || ''

    const typeInfo = ref({
      type: 'error',
      message: ''
    })
    const setMessage = (type, value) => {

      typeInfo.value.type = type
      typeInfo.value.message = value
    }
    const exportFunc = () => {
      console.log('111');
    }

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      userListTable,
      tableColumns,
      searchQuery,
      roleFilter,
      planFilter,
      statusFilter,
      totalUserListTable,
      loading,
      options,
      userTotalLocal,
      selectedRows,

      fetchUsers,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserTotalIcon,
    } = useUsersList(setMessage)

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { title: 'Admin', value: 'admin' },
      { title: 'Author', value: 'author' },
      { title: 'Editor', value: 'editor' },
      { title: 'Maintainer', value: 'maintainer' },
      { title: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { title: 'Basic', value: 'basic' },
      { title: 'Company', value: 'company' },
      { title: 'Enterprise', value: 'enterprise' },
      { title: 'Standard', value: 'standard' },
    ]

    const statusOptions = [
      { title: 'Pending', value: 'pending' },
      { title: 'Active', value: 'active' },
      { title: 'Inactive', value: 'inactive' },
    ]

    const deleteUser = (id) => {
      console.log(11111, id);
      deleteUserApi(id).then(res => {
        setMessage('success', '删除成功')
      }).catch(err => {
        console.log('11111删除', err);
        if (err.message) {
          setMessage('error', err.message)
        }
      })
    }

    const editUserData = ref({})
    watch(isAddNewUserSidebarActive, (value) => {
      if (!value) {
        editUserData.value = {}
      }
    })
    const editUser = (item) => {
      editUserData.value = ({
        ...item
      })
      isAddNewUserSidebarActive.value = true
    }
    const statusUser = (item) => {
      if (item.status === 'active') {
        closeUser(item.id).then(res => {
          item.status = 'banned'
          setMessage('success', '停用成功')
        }).catch(err => {
          if (err.message) {
            setMessage('error', err.message)
          }
        })
      } else {
        openUser(item.id).then(res => {
          item.status = 'active'
          setMessage('success', '启用成功')
        }).catch(err => {
          if (err.message) {
            setMessage('error', err.message)
          }
        })
      }
    }
    return {
      sortBy,
      typeInfo,
      editUserData,
      exportFunc,
      setMessage,
      editUser,
      deleteUser,
      statusUser,
      userListTable,
      tableColumns,
      searchQuery,
      roleFilter,
      planFilter,
      statusFilter,
      totalUserListTable,
      roleOptions,
      planOptions,
      statusOptions,
      loading,
      options,
      userTotalLocal,
      isAddNewUserSidebarActive,
      selectedRows,
      avatarText,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserTotalIcon,
      fetchUsers,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiLockReset
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
