import { getUserListApi, userTokensApi } from '@/plugins/api/modules/user'
import { dateFormat } from '@core/utils'
import {
  mdiAccountCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiCogOutline,
  mdiDatabaseOutline,
  mdiDnsOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

export default function useUsersList(setMessage) {
  const userListTable = ref([])

  const tableColumns = [
    { text: '邮箱', value: 'email', sortable: false },
    { text: '用户名', value: 'name', sortable: false },
    { text: '应用数', value: 'app_count' },
    { text: 'Tokens使用', value: 'totalTokens', sortable: true },
    { text: '状态', value: 'status', sortable: false },
    { text: '活跃日期', value: 'last_active_at', sortable: true },
    {
      text: '操作',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const totalUserListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['totalTokens'],
    sortDesc: [true],
  })
  const userTotalLocal = ref([])
  const selectedRows = ref([])

  // fetch data
  const fetchUsers = () => {
    getUserListApi().then(res => {
      let startDate = dateFormat(+new Date(new Date().getFullYear(), 0, 1), 'yyyy-MM-dd')
      let endDate = dateFormat(+new Date(), 'yyyy-MM-dd')

      startDate += ' 00:00:00'
      endDate += ' 23:59:59'
        console.log('11111111getUserListApi', res);

      userTokensApi(startDate, endDate).then(res2 => {
        console.log('11111111userTokensApi', res2);
        res.data.forEach(item => {

          ['app_count',
            'tokens_agent_chat_dialogue_result',
            'tokens_completion_dialogue_result',
            'tokens_dialogue',
            'tokens_workflow'
          ].forEach(key => {
            const info = res2[key].find(o => o.name === item.name)
            item[key] = info ? (info.count || info.sum) : 0
          })
          item.last_active_at = dateFormat(item.last_active_at, "yyyy-MM-dd hh:mm")

          item.totalTokens = item.tokens_agent_chat_dialogue_result + item.tokens_completion_dialogue_result + item.tokens_dialogue + item.tokens_workflow
        })

        userListTable.value = res.data
        userTotalLocal.value = res.data.length
        totalUserListTable.value = res.data.length
        loading.value = false
      })
    })
    .catch(error => {
      console.log(error)
      if (error.message) {
        setMessage('error', error.message)
      }
    })
  }
  fetchUsers()

  // watch([searchQuery, roleFilter, planFilter, statusFilter, options], () => {
  //   loading.value = true
  //   selectedRows.value = []
  //   fetchUsers()
  // })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'error'

    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return mdiAccountOutline
    if (role === 'author') return mdiCogOutline
    if (role === 'maintainer') return mdiDatabaseOutline
    if (role === 'editor') return mdiPencilOutline
    if (role === 'admin') return mdiDnsOutline

    return mdiAccountOutline
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'

    return 'primary'
  }

  const resolveUserTotalIcon = total => {
    if (total === 'Total Users') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Paid Users') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (total === 'Active Users') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending Users') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    userListTable,
    tableColumns,
    searchQuery,
    roleFilter,
    planFilter,
    statusFilter,
    totalUserListTable,
    loading,
    options,
    userTotalLocal,
    selectedRows,
    fetchUsers,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserTotalIcon,
  }
}
