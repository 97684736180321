import axios from 'axios'

// 启用用户
export const openUser = id => (
  new Promise((resolve, reject) => {
    axios({
      method: 'put',
      url: '/console/api/account/manager/closeUser',
      headers: { 
          'Content-Type': 'application/json'
      },
      data: JSON.stringify({account_id: id})
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      console.log('eee', err.response)
      reject({ code: err.response.status, message: err.response?.data.message || err.response.statusText })
    })
  })
)
// 停用用户
export const closeUser = id => (
  new Promise((resolve, reject) => {
    axios({
      method: 'patch',
      url: '/console/api/account/manager/closeUser',
      headers: { 
          'Content-Type': 'application/json'
      },
      data: JSON.stringify({account_id: id})
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      console.log('eee', err.response)
      reject({ code: err.response.status, message: err.response?.data.message || err.response.statusText })
    })
  })
)
// 删除用户
export const deleteUserApi = account_id => (
  new Promise((resolve, reject) => {
    axios({
      method: 'delete',
      url: '/console/api/account/manager/delUser?account_id'+account_id,
      headers: { 
          'Content-Type': 'application/json'
      },
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      console.log('eee', err.response)
      reject({ code: err.response.status, message: err.response?.data.message || err.response.statusText })
    })
  })
)
// 重置用户密码
export const resetPasswordUser = params => (
  new Promise((resolve, reject) => {
    axios.put('/console/api/account/manager/resetPassword', params).then(res => {
      resolve(res.data)
    }).catch(err => {
      console.log('eee', err.response)
      reject({ code: err.response.status, message: err.response?.data.message || err.response.statusText })
    })
  })
)
// 添加用户
export const addUser = params => {
  const data = {
    email: params.email,
    name: params.name,
    password: params.password,
  }
  return new Promise((resolve, reject) => {
    axios({
      method: 'put',
      url: '/console/api/account/manager/addWithWorkspace',
      headers: { 
          'User-Agent': 'Apifox/1.0.0 (https://apifox.com)', 
          'Content-Type': 'application/json'
      },
      data : JSON.stringify(data)
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      console.log('eee', err.response)
      reject({ code: err.response.status, message: err.response?.data.message || err.response.statusText })
    })
  })
}
// 查询用户
export const getUserListApi = enterprise => (
  new Promise((resolve, reject) => {
    axios.get(`/console/api/account/manager/getAllAccount`).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject({ code: err.response.status, message: err.response.statusText })
    })
  })
)


//获取用户token使用
export const userTokensApi = (startDate, endDate) => {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/customize/accountCountAndAppCountAndTokenCost', {
        params: {
          start_time: startDate,
          end_time: endDate,
        },
      })
      .then(res => {
        console.log('1233', res)
        resolve(res.data)
      }).catch(err => {
        reject({ code: err.response.status, message: err.response.statusText })
      })
  })
}