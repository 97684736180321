<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">{{ editData.id ? '修改密码' : '添加用户'}}</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-user-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
            <v-text-field
            v-model="userData.name"
            :rules="[validators.required]"
            outlined
            :disabled="!!editData.id"
            dense
            label="用户名"
            placeholder="请输入用户名"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="userData.email"
            :rules="[validators.required,validators.emailValidator]"
            outlined
            dense
            type="email"
            :disabled="!!editData.id"
            label="邮箱"
            placeholder="请输入邮箱"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="userData.password"
            :rules="[validators.required]"
            outlined
            dense
            :type="isPasswordVisible ? 'text' : 'password'"
            :append-icon="isPasswordVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
            @click:append="isPasswordVisible = !isPasswordVisible"
            label="密码"
            placeholder="请输入密码"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>
          <v-text-field
            v-model="userData.password2"
            :rules="[validators.required]"
            outlined
            dense
            :type="isPasswordVisible2 ? 'text' : 'password'"
            :append-icon="isPasswordVisible2 ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
            @click:append="isPasswordVisible2 = !isPasswordVisible2"
            label="确认密码"
            placeholder="请输入密码"
            class="mb-6"
          ></v-text-field>

          <v-btn
            color="primary"
            class="me-3"
            type="submit"
          >
            保存
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="resetuserData"
          >
            取消
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import countries from '@/@fake-db/data/other/countries';
import { addUser, resetPasswordUser } from '@/plugins/api/modules/user';
import { emailValidator, required } from '@core/utils/validation';
import { mdiClose, mdiDeleteOutline, mdiEyeOffOutline, mdiEyeOutline, mdiSquareEditOutline } from '@mdi/js';
import { ref, watch } from '@vue/composition-api';


export default {
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    editData: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      name: '',
      email: '',
      password: '',
      password2: ''
    }


    const isPasswordVisible = ref(false)
    const isPasswordVisible2 = ref(false)
    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))

    watch(() => props.isAddNewUserSidebarActive, (value) => {
      if (value) {
        if (props.editData.id) {
          userData.value = {
            name: props.editData.name || '',
            email: props.editData.email || '',
            password: '', // 通常编辑时不显示密码
            password2: '' // 通常编辑时不显示确认密码
          }
        }
      }
    }, { immediate: true })
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
      resetForm()
      emit('update:is-add-new-user-sidebar-active', false)
    }

    const onSubmit = () => {
      if (valid.value) {
        if (userData.value.password !== userData.value.password2) {
          emit('set-message', 'error', '密码不一致')
          return
        }
        if (props.editData.id) {
          resetPasswordUser({
            email: userData.value.email,
            new_password: userData.value.password,
            password_confirm: userData.value.password2,
          }).then(res => {
            console.log('11111resetPassword', res);
            emit('set-message', 'success', '修改密码成功')
            emit('update:is-add-new-user-sidebar-active', false)
            resetuserData()
          }).catch(err => {
            console.log('123123', err)
            if (err.message) {
              emit('set-message', 'error', err.message)
            }
          })
        } else {
          addUser({
            ...userData.value
          }).then(res => {
            emit('refetch-data')
            emit('update:is-add-new-user-sidebar-active', false)
            resetuserData()

            console.log('123123', res);
          }).catch(err => {
            console.log('123123', err)
            if (err.message) {
              emit('set-message', 'error', err.message)
            }
          })
        }

      } else {
        validate()
      }
    }

    return {
      resetuserData,
      form,
      onSubmit,
      countries,
      userData,
      valid,
      validate,
      isPasswordVisible,
      isPasswordVisible2,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiSquareEditOutline, mdiDeleteOutline, mdiEyeOutline, mdiEyeOffOutline
      },
    }
  },
}
</script>
